.link {

    text-decoration: none;
    font-size: 1.5rem;
    margin: 1rem 0;
    float: right;
}

.centerGrid {
    width: 100%;
    margin: 40px auto;
    height: auto;
}

.img {

    height: 200px;
    object-fit: fill;
    border-radius: 10px;
}

.namegame {
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    padding: 10px;
}

.center {
    display: flex;
    justify-content: 'center';
}

.link2 {
    text-decoration: none;
    color: blue;
}

.textnote {
    white-space: pre-wrap;
    word-wrap: break-word;

    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.6;
    letter-spacing: 0.00958em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.item {
    padding: 0px 5px;
}

@media (max-width: 1110px) {
    .container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .item {
        padding: 5px 5px;
    }
}

@media (max-width: 940px) {
    .container {
        grid-template-columns: 1fr 1fr;
    }

    .item {
        padding: 5px 5px;
    }
}

@media (max-width: 600px) {
    .container {
        grid-template-columns: 1fr
    }

    .item {
        padding: 5px 5px;
    }
}

.displayBlock {
    display: none;
    padding: 0px 60px;
}

.displayNone {
    display: block;
    padding: 0px 200px;
}

@media (max-width: 600px) {
    .displayBlock {
        display: block;
    }

    .displayNone {
        display: none;
    }
}

.lightboxImg {
    max-width: 60%;
    max-height: 70vh;
    margin-left: 256px;
    margin-top: 56px;
    color: #fff;
    z-index: 2;
}

@media (max-width: 600px) {
    .lightboxImg {
        margin-left: 0;
        max-width: 70vw;
    }
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .lightbox {
        max-width: 100vw;
    }
}

.center {
    display: flex;
    justify-content: center;
}

.lightboxOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    margin-top: 128px;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

@media (max-width: 600px) {
    .lightboxOverlay {
        margin-top: 120px;
    }
}

.headers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 64px;
    min-height: 64px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

@media (max-width: 600px) {
    .headers {
        margin-top: 56px;
    }
}

.lightboxContent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 3;
}



.refreshButton {
    position: absolute;
    top: 10px;
    right: 60px;
    z-index: 3;
}


.prevButton {
    position: absolute;
    top: 50%;
    left: 266px;
    transform: translateY(-50%);
    z-index: 3;

}

@media (max-width: 600px) {
    .prevButton {
        left: 10px;
    }
}

.nextButton {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 3;

}

.icon {
    font-size: 2rem;
    color: #fff;
}

.photoimg {
    width: 90px;
    height: 90px;
    margin: 4px;
}

.input {
    display: none;
    color: #3f50b5;
    margin: 16px 0;
    /* Tương đương với theme.spacing(2, 0) */
}