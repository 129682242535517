.link {

    text-decoration: none;
    font-size: 1.5rem;
    margin: 1rem 0;
    float: right;
}

.centerGrid {
    width: 100%;
    margin: 40px auto;
    height: auto;
}

.img {

    height: 200px;
    object-fit: fill;
    border-radius: 10px;
}

.namegame {
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    padding: 10px;
}

.center {
    display: flex;
    justify-content: 'center';
}

.Autocomplete {
    min-width: 500px;

}

@media screen and (max-width: 768px) {

    .Autocomplete {
        min-width: 300px;

    }

}