.link {
    color: inherit;
    text-decoration: none;
    float: right;
    margin: 1rem 0;
}

.centerGrid {
    width: 100%;
    margin: 40px auto;
    height: auto;
}