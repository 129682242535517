.link {

    text-decoration: none;
    font-size: 1.5rem;
    margin: 1rem 0;
    float: right;
}

.centerGrid {
    width: 100%;
    margin: 40px auto;
    height: auto;
}

.text {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.img {

    height: 200px;
    object-fit: fill;
    border-radius: 10px;
}

.namegame {
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    padding: 10px;
}

.center {
    display: flex;
    justify-content: 'center';
}