.errortext {
    margin: 8px 0;
    /* Tương đương với theme.spacing(1, 0) */
}

.budget,
.budget2 {
    display: flex;
    margin: 8px 0;
    /* Tương đương với theme.spacing(1, 0) hoặc theme.spacing(3, 0, 0, 0) */
}

.budget2 {
    margin-top: 24px;
    /* Tương đương với theme.spacing(3, 0, 0, 0) */
}

.fontText {
    margin: 8px 0;
    /* Tương đương với theme.spacing(1, 0) */
}

.paper {
    max-width: 600px;
    margin: auto;
    background-color: #fafafa;
}

.name {
    font-weight: 500;
    font-size: 30px;
    margin: 0 0 16px 0;
    /* Tương đương với theme.spacing(0, 0, 2, 0) */
}

.button {
    margin: 40px 0;
    /* Tương đương với theme.spacing(5, 0) */
}

.nameW {
    flex-grow: 1;
    font-weight: 500;
    font-size: 16px;
    min-width: 50%;
    margin: 16px 16px 0 0;
    /* Tương đương với theme.spacing(2, 2, 0, 0) */
}

.nameWText {
    flex-grow: 1;
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 16px 0;
}

.nameW2 {
    margin: 16px 16px 0 0;
    /* Tương đương với theme.spacing(2, 2, 0, 0) */
}

.column {
    flex-basis: 33.33%;
}

.columnQuantity {
    flex-basis: 33.33%;
}

.photoimg {
    width: 90px;
    height: 90px;
    margin: 4px;
}





@media (max-width: 600px) {

    .column,
    .columnQuantity {
        flex-basis: 40%;
    }
}

.input {
    display: none;
    color: #3f50b5;
    margin: 16px 0;
    /* Tương đương với theme.spacing(2, 0) */
}

.large {
    width: 200px;
    /* Tương đương với theme.spacing(25) */
    height: 200px;
    /* Tương đương với theme.spacing(25) */
    margin: 16px 0;
    /* Tương đương với theme.spacing(2, 0) */
}

.photoimg {
    width: 90px;
    height: 90px;
    margin: 8px;
    /* Tương đương với theme.spacing(1) */
}

.center {
    display: flex;
    justify-content: center;
}

.photoContainer {
    position: relative;
    display: flex;
}

.deleteImg {
    position: absolute;
    top: 0;
    left: 0;
}